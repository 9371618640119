<template>
  <div class="content-box">
    <div v-loading="loading" class="mx-auto login p-20 box-shadow">
      <div class="header text-center mb-25">
        <h2 class="mb-0">{{ $t("register.title") }}</h2>
      </div>
      <el-divider />
      <el-form ref="ruleForm" :model="formData" :rules="rules">
        <el-form-item prop="email">
          <el-input
            v-model="formData.email"
            prefix-icon="el-icon-user"
            :placeholder="$t('login.emailPhone')"
          />
          <!-- @input="emailInput()" -->
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="formData.password"
            prefix-icon="el-icon-lock"
            :placeholder="$t('login.password') + $t('login.min6')"
            show-password
          />
        </el-form-item>
        <el-form-item prop="re_password">
          <el-input
            v-model="formData.re_password"
            prefix-icon="el-icon-lock"
            :placeholder="$t('register.re_password') + $t('login.min6')"
            show-password
          />
        </el-form-item>
        <el-form-item prop="invitation_code">
          <el-input
            v-model="formData.invitation_code"
            prefix-icon="el-icon-present"
            :placeholder="$t('register.invitation_code')"
          />
        </el-form-item>
        <!-- <el-form-item prop="email_captcha">
                    <el-input v-model="formData.email_captcha" prefix-icon="el-icon-s-check" :placeholder="$t('register.email_captcha')">
                        <template slot="append">
                            <el-button v-if="sendEmailTimeout <= 0" plain type="success" native-type="button" @click="sendEmailCaptcha">{{ $t('register.send_to_email') }}</el-button>
                            <el-button v-else plain type="success" disabled native-type="button">Retry in {{ sendEmailTimeout }}s</el-button>
                        </template>
                    </el-input>
                </el-form-item> -->
      </el-form>
      <el-divider />
      <div class="mt-30 mx-20">
        <el-button class="w-100" type="primary" @click="submitForm">{{
          $t("base.submit")
        }}</el-button>
      </div>
      <div class="mx-20">
        <div class="agree">
          <div class="radio-box">
            <el-checkbox v-model="radio" style="margin-right: 0"></el-checkbox>
          </div>
          <div class="agree_text">
            {{ $t("r.r1") }}
            <!-- <span class="agree_link" @click="agreeFn">{{ $t("r.r2") }}</span> -->
             <!-- 因为需要上页的注册页表单保留填写数据所以新建窗口打开 -->
            <router-link class="agree_link" target="_blank" :to="{path:'/help/4'}">{{ $t("r.r2") }}</router-link>
          </div>
        </div>
      </div>
      <div class="mt-30 mb-20 text-center font-size-14">
        {{ $t("register.already_have_an_account") }}
        <el-link class="color-primary is-link" @click="$nav.replace('login')">{{
          $t("register.sign_in")
        }}</el-link>
      </div>
    </div>
  </div>
</template>

<script>
import { validEmail } from "@/services/validate";
export default {
  data() {
    //验证重复密码
    var repasswordValidator = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("login.rules3")));
      } else if (value !== this.formData.password) {
        callback(new Error(this.$t("login.rules3_2")));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      captchaSrc: null,
      formData: {
        email: "",
        password: "",
        invitation_code: "",
        // captcha: ''
      },
      timer: null,
      sendEmailTimeout: 0,
      radio: true,
      rules: {
        // email: [
        //     { required: true, type: 'email', message: 'Please enter Email address', trigger: 'blur' }
        // ],
        // password: [
        //     { required: true, type: 'string', min: 6, message: 'More than 6 characters', trigger: 'blur' }
        // ],
        // invitation_code: [
        //     { required: true, type: 'string', min: 4, message: 'More than 4 characters', trigger: 'blur' }
        // ],
        // email_captcha: [
        //     { required: true, type: 'string', min: 4, message: 'More than 4 characters', trigger: 'blur' }
        // ]

        email: [
          {
            required: true,
            // type: "email",
            message: this.$t("login.rules1"),
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            min: 6,
            message: this.$t("login.rules2"),
            trigger: "blur",
          },
        ],
        re_password: [
          {
            required: true,
            min: 6,
            message: this.$t("login.rules3"),
            trigger: "blur",
          },
          { validator: repasswordValidator, trigger: "blur" },
        ],
        invitation_code: [
          {
            required: true,
            message: this.$t("login.rules4"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  beforeDestroy: function () {
    if (this.timer === null) return;
    window.clearInterval(this.timer);
  },
  created() {
    if (this.$route.query.t) {
      this.formData.invitation_code = this.$route.query.t;
    }
  },
  methods: {
    sendEmailCaptcha: function () {
      if (validEmail(this.formData.email)) {
        this.loading = true;
        this.$request
          .post("register/email_captcha", this.formData)
          .then(() => {
            this.loading = false;
            if (this.timer !== null) {
              window.clearInterval(this.timer);
            }
            this.sendEmailTimeout = 120;
            this.timer = window.setInterval(() => {
              this.sendEmailTimeout--;
              if (this.sendEmailTimeout <= 0) {
                this.timer = null;
              }
            }, 1000);
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.$message({
          message: "Please Enter",
          type: "error",
        });
      }
    },
    emailInput: function () {
      this.formData.captcha = "";
      this.captchaSrc = null;
    },
    submitForm: async function () {
      let that = this
      if (!this.radio) {
        return this.$message({
          showClose: true,
          type: "error",
          duration: 2000,
          message: this.$t("login.rules5"),
        });
      }
      await this.$refs.ruleForm.validate();
      //   if (this.formData.password != this.formData.re_password) {
      //     this.$message({
      //       message: "The two passwords entered are inconsistent",
      //       type: "error",
      //     });
      //     return;
      //   }
      this.loading = true;
      try {
        // await this.$request.post("register", this.formData);
        let data = await this.$request.post("v1/login/register", this.formData);
        this.loading = false;
        if (data.code == 20000) {
          that.$message({
            showClose: true,
            type: "success",
            duration: 2000,
            message: that.$t('registerS'),
            onClose: function () {
              that.$store.commit("userEmail", that.formData.email);
              that.$nav.replace("login");
            },
          });
        }
        // this.$store.commit("userEmail", this.formData.email);
        // this.$nav.replace("login");
      } catch (error) {
        
        // this.formData.captcha = "";
        // this.captchaSrc = null;
        this.loading = false;
      }
    },

    agreeFn() {
      this.$router.push("../help/4");
    },
  },
};
</script>
<style lang="scss" scoped>
.login {
  width: 400px;
  margin-top: 80px;
  margin-bottom: 80px;
  background-color: white;
  color: #333;
  border-radius: 8px;
}
.agree {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 10px;
}
.radio-box {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.agree_text {
  line-height: 20px;
}
.agree_link {
  color: #f9a825;
  cursor: pointer;
}
.radio-box {
  margin-right: 10px;
}
</style>